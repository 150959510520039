<template>
  <div class="yx-wid footer">
    <div class="yx-wid-12">
      <ul class="link-list">
        <li v-for="(item, index) in 11" :key="index">
          <img
            :src="
              require('@/assets/images/home/footerlogo/' +
                (item < 10 ? '0' + item : item) +
                '.png')
            "
            class="mr"
          />
        </li>
      </ul>
      <div class="guide-list">
        <a
          href="javascript:;"
          @click="jumpGO(item)"
          v-for="(item, index) in guides"
          :key="index"
          >{{ item.name }}</a
        >
      </div>
      <div class="copy-text">{{ $t("footer.copytext") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guides: [
        { name: this.$i18n.t("footer.nav1"), link: "/help?type=kh" },
        { name: this.$i18n.t("footer.nav2"), link: "/help?type=cpqa" },
        { name: this.$i18n.t("footer.nav3"), link: "/help?type=ysbhgz" },
        { name: this.$i18n.t("footer.nav4"), link: "/help?type=gzytk" },
        // { name: this.$i18n.t("footer.nav5"), link: "/help?type=lxwm" },
        // { name: this.$i18n.t("footer.nav6"), link: "/agent" },
        // { name: this.$i18n.t("footer.nav7"), link: "/download" },
      ],
    };
  },
  methods: {
    jumpGO(val) {
      if (!val.link) return;
      this.$router.push(val.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  text-align: center;
  position: relative;
  line-height: 1;
}

.link-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgba(113, 128, 153, 0.2);
  }

  li {
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  img {
    height: 34px;
  }
}

.copy {
  display: flex;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  padding: 0 130px;
  margin-top: 18px;
  font-size: 14px;
  color: #878ea6;
  flex-direction: column;
}

.rz-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  li {
    margin: 0 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &:hover {
      color: white;

      p {
        color: white;
      }

      span {
        background-position: -167px 0;
      }
    }

    span {
      width: 165px;
      height: 34px;
      display: block;
      background: url("@/assets/images/logo15.png") no-repeat;
      background-size: 958px 41px;
      background-position: 0 0;
    }

    p {
      font-size: 14px;
      color: #878ea6;
      margin-top: 8px;
    }

    &:nth-child(2) {
      &:hover {
        span {
          background-position: -507px 0;
        }
      }

      span {
        width: 171px;
        height: 41px;
        background-position: -334px 0;
      }
    }

    &:nth-child(3) {
      &:hover {
        span {
          background-position: -820px 0;
        }
      }

      span {
        width: 138px;
        height: 41px;
        background-position: -680px 0;
      }
    }
  }
}

.guide-list {
  display: flex;
  justify-content: center;
  color: #c0c4d0;
  font-size: 15px;
  padding: 20px 0;

  a {
    position: relative;
    padding: 0 16px;
    color: rgba(110, 110, 114, 0.6);
    border-right: 1px solid #d6e2f3;

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: rgba(110, 110, 114, 1);
    }
  }
}

.copy-text {
  text-align: center;
  font-size: 13px;
  color: #6e6e72b3;
  padding-bottom: 20px;
}
</style>
