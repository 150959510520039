<template>
  <div
    id="app"
    :class="{
      bgcolor: ['home'].includes($route.meta.layout),
    }"
  >
    <Header v-if="isHeader" />
    <router-view />
    <Footer v-if="isFooter" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isHeader: true,
      isFooter: true,
    };
  },
  watch: {
    $route(val) {
      this.infoRoute(val.meta);
    },
    isLogin(val) {
      if (val) {
        this.checkIsSetWidthPassword();
      }
    },
  },
  mounted() {
    this.infoRoute(this.$route.meta);
    this.getWebsiteBasicInfo();
    this.getUserInfo();
    this.getRegisterItem();
    this.checkIsSetWidthPassword();
  },
  computed: {
    ...mapState(["languages"]),
  },
  methods: {
    // 是否设置支付密码
    checkIsSetWidthPassword() {
      if (!this.isLogin) return;
      this.openSms();
      this.$Api.checkIsSetWidthPassword().then((res) => {
        if (res) {
          const val = res.msg === "yes" ? true : false;
          this.$store.dispatch("setPayPwdAc", val);
        }
      });
    },
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.$store.dispatch("setRegAuthAc", res.registerItemList[0].isShow);
        }
      });
    },
    // 个人资料
    getUserInfo() {
      const isLogin = localStorage.getItem("AppToken");
      if (!isLogin) {
        localStorage.removeItem("AppToken");
        localStorage.removeItem("userInfo");
        return;
      }
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          this.changeLanguageAndCurrenc(res.userInfo.languageType, "lang");
          this.changeLanguageAndCurrenc(res.userInfo.currencyType, "area");
        }
      });
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      if (type === "area") {
        params.currencyType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          if (type === "lang") {
            let langtarget = this.languages.find(
              (item) => item.id === val
            ).type;
            this.$i18n.locale = langtarget;
            localStorage.setItem("lang", langtarget);
          } else {
            localStorage.setItem("area", val);
          }
        }
      });
    },
    // 是否开启短信
    openSms() {
      this.$Api
        .getSysConfigValue({
          key: "sms_switch",
        })
        .then((res) => {
          const tagetVal = res.sysConfigValue === "off" ? false : true;
          this.$store.dispatch("setSmsAc", tagetVal);
        });
    },
    infoRoute(val) {
      const { head, footer } = val;
      if (!head) {
        this.isHeader = true;
      } else {
        this.isHeader = false;
      }
      if (!footer) {
        this.isFooter = true;
      } else {
        this.isFooter = false;
      }
    },
    getWebsiteBasicInfo() {
      this.$Api.getWebsiteBasicInfo().then((res) => {
        const baseInfo = res.websiteBasicList;
        this.$store.dispatch("setBaseInfoAC", baseInfo);
        this.exitIco(baseInfo);
        this.setTitle(baseInfo);
      });
    },
    setTitle(val) {
      document.title = val.find(
        (item) => item.configKey === "title"
      ).configValue;
    },
    exitIco(val) {
      const ico = document.querySelector('link[rel="icon"]');
      const title = document.querySelector("title");
      title.innerHTML = val[0].configValue;
      if (ico !== null) {
        ico.href = val[1].configValue;
      } else {
        ico = document.createElement("link");
        ico.rel = "icon";
        ico.href = val[1].configValue;
        document.head.appendChild(ico);
      }
    },
  },
};
</script>
