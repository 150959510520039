var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yx-wid yx-bg sj-layout-over"},[_c('div',{staticClass:"yx-wid banner"},[_vm._l((_vm.banners),function(item,index){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imgUrl),expression:"item.imgUrl"}],key:index,class:{ hover: index === _vm.bannerInd },on:{"click":function($event){return _vm.openBanner(item)}}})}),_c('div',{staticClass:"banner-nav"},_vm._l((_vm.banners),function(item,index){return _c('span',{key:index,class:{ hover: index === _vm.bannerInd },on:{"click":function($event){return _vm.tabBanner(item, index)}}})}),0),_c('div',{staticClass:"banner-prev",on:{"click":_vm.tabPrev}}),_c('div',{staticClass:"banner-next",on:{"click":_vm.tabNext}})],2),_c('div',{staticClass:"yx-wid home-ad"},[_c('div',{staticClass:"yx-wid-12"},[_c('div',{staticClass:"home-ad-icon"}),(_vm.newsList)?_c('div',{ref:"scorll",staticClass:"home-ad-scroll"},[_c('div',{ref:"scorllWid",staticClass:"home-ad-text",style:({
            transform: 'translateX(' + _vm.scorllLeftVal + 'px)',
            transition: _vm.scrollLeftStar,
          }),on:{"mouseout":_vm.scrollStar,"mouseover":_vm.moveScroll}},_vm._l((_vm.newsList),function(item,index){return _c('div',{key:index,staticClass:"row",domProps:{"innerHTML":_vm._s(item.scrollContent)},on:{"click":function($event){return _vm.openMessage(item)}}})}),0)]):_vm._e()])]),_c('div',{staticClass:"yx-wid-12 yx-load"},[_vm._m(0),(_vm.baseInfo && _vm.baseInfo[22])?_c('div',{staticClass:"sj-download"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"row-left"},[_c('h4',[_vm._v("IOS下载")]),_c('div',{staticClass:"code"},[_c('QRCode',{attrs:{"text":_vm.baseText('iosApp'),"width":120,"height":120}}),_c('p',[_vm._v("扫码下载")])],1),_c('div',{staticClass:"ym-add"},[_vm._v(" 手机打开："),_c('a',{staticClass:"text-over",attrs:{"href":_vm.baseInfo[22].configValue,"target":"_black"}},[_vm._v(_vm._s(_vm.baseText("iosApp")))])])]),_vm._m(1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"row-left"},[_c('h4',[_vm._v("android下载")]),_c('div',{staticClass:"code"},[_c('QRCode',{attrs:{"text":_vm.baseText('androidApp'),"width":120,"height":120}}),_c('p',[_vm._v("扫码下载")])],1),_c('div',{staticClass:"ym-add"},[_vm._v(" 手机打开："),_c('a',{staticClass:"text-over",attrs:{"href":_vm.baseInfo[22].configValue,"target":"_black"}},[_vm._v(_vm._s(_vm.baseText("androidApp")))])])]),_vm._m(2)])]):_vm._e()]),_c('section',{staticClass:"yx-wid-12 ab-hotgame"},[_vm._m(3),_c('div',{staticClass:"sj-game"},_vm._l((_vm.homeGames),function(item,index){return _c('div',{key:index,staticClass:"row",style:({
          backgroundImage:
            'url(' +
            require('@/assets/images/home/' + item.layout + '.webp') +
            ')',
        }),on:{"click":function($event){return _vm.$router.push(item.link)}}},[_c('h3',[_vm._v(_vm._s(item.gameTypeName))]),_c('p',[_vm._v(_vm._s(item.gameDescription))])])}),0)]),_c('section',{staticClass:"yx-wid-12 ab-hotgame"},[_vm._m(4),_c('div',{staticClass:"sj-service"},_vm._l((_vm.serviceList),function(item,index){return _c('div',{key:index,staticClass:"row",style:({
          backgroundImage: 'url(' + item.icon + ')',
        })},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.sec))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sj-title"},[_c('h3',[_vm._v("下载APP，")]),_c('span',{staticClass:"span"},[_vm._v("指尖投注，随时随地畅玩。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-right"},[_c('img',{attrs:{"src":require("@/assets/images/download02.webp")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-right"},[_c('img',{attrs:{"src":require("@/assets/images/download-01.webp")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sj-title"},[_c('h3',[_vm._v("游戏中心，")]),_c('span',{staticClass:"span"},[_vm._v("甄选业内各类游戏，玩法丰富多样。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sj-title"},[_c('h3',[_vm._v("技术驱动娱乐，")]),_c('span',{staticClass:"span"},[_vm._v("顶尖技术为您保驾护航。")])])
}]

export { render, staticRenderFns }